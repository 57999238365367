import React, {Component} from 'react';
import PrivatePage from "../../common/containers/PrivatePage";
import Content from "../../common/containers/Content";
import Col from "../../common/containers/Col";
import Row from "../../common/containers/Row";
import Panel from "../../common/containers/Panel";
import Textarea from "../../common/components/form/Textarea";
import Button from "../../common/components/Button";
import Waiting from "../../common/containers/Waiting";
import {AlertError, AlertSuccess} from "../../common/components/Alert";
import Ajax from "../../common/ajax";
import Config from "../../config";
import ListSelect from "./ListSelect";
import ProgressBar from "../../common/components/ProgressBar";
import Input from "../../common/components/form/Input";

class MailSubscribePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batchSize: 10,
            listId: '',
            backupListId: '',
            rawEmailList: '',
            emailList: [],
            processing: false,
            hasErrorOnLineNumber: false,
            error: false,
            success: false,
            completePercentage: 0,
        };

        this.onTextareaChange = this.onTextareaChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.postData = this.postData.bind(this);
        this.emailListLength = 0;
    }

    onTextareaChange(event) {
        const {value} = event.target;
        this.setState(state => {
            return {
                ...state,
                rawEmailList: value,
                emailList: this.convertRawTextToObjects(value),
            };
        });
    }

    convertRawTextToObjects(rawEmailList) {
        const emailList = [];
        const lines = rawEmailList.trim().split('\n');
        if (lines.length) {
            let lineErrorNumber = null;

            lines.forEach((line, idx) => {
                if (!line.trim()) {
                    return;
                }
                // Gets the full name and email
                const items = line.trim().split(',').filter(i => !!i.trim());

                if (items.length < 2 || items.length > 3) {
                    lineErrorNumber = idx + 1;
                    return;
                }

                if (items.length === 2) {
                    // Adds the first name to the end
                    items.push(items[0].trim().split(' ')[0].trim())
                }

                if (items[1].split('@').length !== 2) {
                    lineErrorNumber = idx + 1;
                    return;
                }

                if (items.length === 3) {
                    // Adds to the list
                    emailList.push({
                        fullName: items[0].trim(),
                        email: items[1].trim(),
                        firstName: items[2].trim(),
                    });
                }
            });

            this.setState(state => ({
                ...state,
                hasErrorOnLineNumber: lineErrorNumber,
            }));
        }
        return emailList;
    }

    objectsToRawText(objects) {
        return objects.map(i => `${i.fullName},${i.email},${i.firstName}`).join('\n');
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState(state => ({
            ...state,
            processing: true,
            success: false,
            error: false,
            completePercentage: 0,
        }));

        this.emailListLength = this.state.emailList.length;
        this.postData();
    }

    postData() {
        if (!this.state.emailList.length) {
            this.setState(state => ({
                ...state,
                success: true,
                processing: false,
            }));
            return;
        }

        const remainingEmails = this.state.emailList.slice();
        const emails = remainingEmails.splice(0, this.state.batchSize);

        const data = {
            listId: this.state.listId,
            backupListId: this.state.backupListId,
            emailList: emails,
        };

        Ajax.post(`${Config.apiHost}mailing/subscribe/`, data).done(response => {
            const amountEmailConsumed = this.emailListLength - remainingEmails.length;
            this.setState(state => ({
                ...state,
                // Updates the emails list to control which has been sent, also updates the table.
                emailList: remainingEmails,
                // Updates the text, soh it will remain only the emails not sent.
                rawEmailList: this.objectsToRawText(remainingEmails),
                completePercentage: Math.round((amountEmailConsumed/this.emailListLength) * 100),
            }));
            this.postData();
        }).fail(jqXHR => {
            console.log(jqXHR);
            this.setState(state => ({
                ...state,
                error: true,
                processing: false,
            }));
        });
    }

    render() {
        return (
            <PrivatePage title="Inscrição de e-mails em lote" isAdminOnly={true}>
                <Content>
                    <Row>
                        <Col md={10} mdOffset={1}>
                            <Panel title="Formulário de inscrição em lote">
                                {this.state.success && (
                                    <AlertSuccess>
                                        Lista de e-mail enviada com sucesso.
                                    </AlertSuccess>
                                )}

                                {this.state.error && (
                                    <AlertError>
                                        Erro ao enviar lista de e-mail.
                                    </AlertError>
                                )}

                                {this.renderForm()}
                            </Panel>
                        </Col>
                    </Row>
                </Content>
            </PrivatePage>
        );
    }

    renderForm() {
        return (
            <form onSubmit={this.onSubmit}>
                <Waiting isProcessing={this.state.processing}>
                    <Row>
                        <Col md={5}>
                            <ListSelect
                                onChange={listId =>
                                    this.setState(s => ({...s, listId}))}
                            />
                        </Col>
                        <Col md={5}>
                            <ListSelect
                                label="Lista de backup"
                                onChange={listId =>
                                    this.setState(s => ({...s, backupListId: listId}))}
                                required={false}
                            />
                        </Col>
                        <Col md={2}>
                            <Input
                                label="Tamanho do lote"
                                name="batchSize"
                                value={this.state.batchSize}
                                type="number"
                                onChange={e => {
                                    e.persist();
                                    this.setState(s =>
                                        ({...s, batchSize: e.target.value}));}}
                            />
                        </Col>
                    </Row>

                    <Textarea
                        label="Lista"
                        name="rawEmailList"
                        value={this.state.rawEmailList}
                        onChange={this.onTextareaChange}
                        rows="10"
                        required={true}
                        showSelectedLineNumber={true}
                        help="Cada linha de conter Nome,Email ou Nome,Email,PrimeiroNome"
                        placeholder="Nome, E-mail"
                    />


                    {this.state.hasErrorOnLineNumber && (
                        <AlertError>
                            A lista contém erros na linha: {this.state.hasErrorOnLineNumber}
                        </AlertError>
                    )}
                </Waiting>

                {this.state.processing && (
                    <ProgressBar
                        percentage={this.state.completePercentage}
                        stripped={true}
                        animated={this.state.completePercentage < 100}
                    />
                )}

                <Button
                    size="lg"
                    submit={true}
                    disabled={
                        !this.state.emailList.length ||
                        this.state.hasErrorOnLineNumber ||
                        this.state.processing
                    }
                >
                    Enviar ao servidor
                </Button>

                {this.renderPreview()}
            </form>
        );
    }

    renderPreview() {
        return (
            <>
                <h3>Email list preview</h3>

                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Full name</th>
                        <th>E-mail</th>
                        <th>First name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.emailList.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.fullName}</td>
                            <td>{item.email}</td>
                            <td>{item.firstName}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        );
    }
}

export default MailSubscribePage;